<template>
  <!-- 改了 -->
  <div class="main" :class="show ? 'h5' : ''">
    <div class="htop">
      <mtop />
    </div>
    <div class="banner">
      <img src="../assets/sdImages/party/444.jpg" alt="szzc" class="simg" />
    </div>
    <!-- <div ref="mySwiper" class="swiper-container banner" id="banner">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img
            src="../assets/sdImages/party/444.jpg"
            alt=""
            class="simg"
          />
        </div>
        <div class="swiper-slide">
          <img
            src="../assets/sdImages/party/444.jpg"
            alt=""
            class="simg"
          />
        </div>
        <div class="swiper-slide">
          <img
            src="../assets/sdImages/party/444.jpg"
            alt=""
            class="simg"
          />
        </div>
        <div class="swiper-slide">
          <img
            src="../assets/sdImages/party/444.jpg"
            alt=""
            class="simg"
          />
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div> -->
    <!-- <div class="banner">
			<div class="banimg">
				<div class="title">善达信息-国内领先的区块链技术服务商</div>
				<div class="tip">让技术更可信，让营销更简单</div>
			</div>
		</div> -->
    <div class="main_box" id="cu">
      <div class="dj-title">
        <span class="title-red">简&nbsp;</span>
        <span class="title-black">介</span>
      </div>
      <div class="box1">
        <div class="left_con">
          <!-- <div class="title">善达信息</div> -->
          <div class="cont">
            合肥善达信息科技有限公司党支部于2022年6月成立，是合肥市蜀山区<span
              class="red-text"
              >“双强六好”</span
            >
            非公企业党组织，在上级党委的领导下,切实把党建工作与企业发展有机结合,围绕公司中心工作,充分发挥党组织的<span
              class="red-text"
              >政治核心作用和党员的先锋模范作用</span
            >。
          </div>
          <div class="cont">
            目前已成立工会、妇委会、共青团组织，通过党对群团工作的统一领导，带领群团组织更好地发挥桥梁和纽带作用，围绕中心、建设队伍、服务职工，推动群团组织改革创新、增强活力，推进党的群团工作高质量发展。
          </div>
        </div>
        <div class="out-swiper-container">
          <div
            class="swiper-container swiper-container1"
            id="imgSwiper"
            ref="myimgSwiper"
          >
            <!-- <div class="Gtitle">合作伙伴</div> -->
            <div class="swiper-wrapper">
              <template v-if="swiperList.length > 0">
                <div
                  class="imgBox swiper-slide"
                  v-for="(item, index) in swiperList"
                  :key="index"
                  @click="toNews(item)"
                >
                  <img :src="item.imgUrl" alt="" />
                </div>
              </template>
              <template v-else> </template>
            </div>
            <!-- 设置左右按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="about_box">
      <div class="btop">
        <div class="title">品牌特色</div>
        <div class="tips">
          通过高质量的党建引领和助推企业发展，以社会责任建设为抓手推进企业党建。
        </div>
      </div>
      <div class="box2">
        <div class="left-flag">
          <img src="../assets/sdImages/party/Group1608.png" alt="" />
        </div>
        <div class="center-content">
          党支部作为核心，通过高质量的党建引领和助推企业发展，以社会责任建设为抓手推进企业党建。工会与妇联注重服务职工和妇女群众，通过职工之家、技能培训、文体活动、关怀帮扶、亲子活动等多种形式，增强职工的幸福感和归属感，营造和谐的企业文化氛围。坚持党建带团建的原则，推动非公企业团组织建设，聚焦青年成长成才，激发青年创新活力，打造共青团特色品牌，激动青年团员在企业发展中发挥先锋模范作用。
        </div>
        <div class="right-flag">
          <img src="../assets/sdImages/party/Group1609.png" alt="" />
        </div>
        <div class="ts-img">
          <div class="img" @click="gotoDetail(0)">
            <img src="../assets/sdImages/party/Group_12379@2x.png" alt="" />
          </div>
          <div class="img" @click="gotoDetail(1)">
            <img src="../assets/sdImages/party/Group_12376@2x.png" alt="" />
          </div>
          <div class="img" @click="gotoDetail(2)">
            <img src="../assets/sdImages/party/Group_12377@2x.png" alt="" />
          </div>
          <div class="img" @click="gotoDetail(3)">
            <img src="../assets/sdImages/party/Group_12378@2x.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="main_box zbbg" id="cu2">
      <div class="dj-title">
        <span class="title-red">党群</span>
        <span class="title-black">动态</span>
      </div>
      <div class="boxzb">
        <div class="boxzb-left">
          <div ref="mySwiper" class="swiper-container" id="zbswiper">
            <div class="swiper-wrapper">
              <template v-if="swiperList2.length > 0">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in swiperList2"
                  :key="index"
                  @click="tosomePage(item)"
                >
                  <img :src="item.imgUrl" alt="" />
                  <div class="swiper-pagination-text">
                    <div class="swiper-pagination-text-left">
                      <div class="num">
                        {{ item.createTime.substring(8, 10) }}
                      </div>
                      <div class="time">
                        {{ item.createTime.substring(0, 7) }}
                      </div>
                    </div>
                    <div class="swiper-pagination-text-right">
                      {{ item.description }}
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="swiper-slide">
                  <van-empty description="暂无更多信息~" />
                </div>
              </template>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div class="boxzb-right">
          <!-- <div class="boxzb-right-list-box">
						<div class="boxzb-right-list" :id="'ts'+index" v-for="(item, index) in swiperVerticalData" :key="item.id">
						<div class="content">{{ item.name }}</div>
						<div class="time">{{ item.time }}</div>
					</div>
					</div>
					<div class="boxzb-right-btn">
						<div class="up" @click="upList()"><i class="el-icon-arrow-up"></i></div>
						<div class="line"></div>
						<div class="down" @click="downList()"><i class="el-icon-arrow-down"></i></div>
					</div> -->
          <swiperVertical
            :swiperVerticalData="swiperVerticalData"
            :pClass="'di'"
            @toteamPage="toteamPage"
          >
          </swiperVertical>
        </div>
        <div
          class="boxzb-bottom"
          v-for="(item, index) in list"
          :key="index"
          @click="toPagedj(item)"
        >
          <div class="boxzb-bottom-img">
            <img :src="item.topImgUrl" alt="" />
          </div>
          <div class="boxzb-bottom-text">
            {{ item.name }}
          </div>
        </div>
        <!-- <div class="boxzb-bottom">
          <div class="boxzb-bottom-img">
            <img src="@/assets/sdImages/party/sb1.png" alt="" />
          </div>
          <div class="boxzb-bottom-text">
            中水三立常仁凯携队访善达，共建共创智慧...
          </div>
        </div>
        <div class="boxzb-bottom">
          <div class="boxzb-bottom-img">
            <img src="@/assets/sdImages/party/sb2.png" alt="" />
          </div>
          <div class="boxzb-bottom-text">小盒子守护大梦想，用爱传递温暖...</div>
        </div>
        <div class="boxzb-bottom">
          <div class="boxzb-bottom-img">
            <img src="@/assets/sdImages/party/sb1.png" alt="" />
          </div>
          <div class="boxzb-bottom-text">冬去春来万象新，党旗飘扬添绿荫...</div>
        </div> -->
      </div>
    </div>
    <mbottom />
  </div>
</template>

<script>
import { connectTron } from "@/api/Public";
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import swiperVertical from "../components/swiperVertical.vue";
export default {
  data() {
    return {
      heightList: [],
      center: { lng: 111, lat: 111 }, //lng代表纬度 lat代表经度
      zoom: 19,
      firstIndex: 1,
      show: false,
      list: [],
      swiperVerticalData: [
        // {
        //   id: 1,
        //   name: "1合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 2,
        //   name: "2合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 3,
        //   name: "3合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 4,
        //   name: "4合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 5,
        //   name: "5合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 6,
        //   name: "6合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 7,
        //   name: "7合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 8,
        //   name: "8合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 9,
        //   name: "9合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 10,
        //   name: "10合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 11,
        //   name: "11合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
      ],
      swiperList: [],
      swiperList2: [],
      list2: [],
      current2: 0,
    };
  },

  components: {
    mtop,
    mbottom,
    swiperVertical,
  },

  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
    // this.initImgSwiper();
    // this.initSwiper();
    // this.initBannerSwiper();
  },
  watch: {
    "$store.getters.sid": function (val) {
      this.handleFiexdToolItem(val);
    },
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    "$route.query.current": function (val) {
      this.current2 = val;
      this.$nextTick(() => {
        this.getScroll();
      });
    },
    deep: true,
    immediate: true,
  },
  created() {
    this.getList();
    this.getList2();
    // 获取轮播图
    this.getSwiperList();
    this.getSwiperList2();
    this.current2 = this.$route.query.current;
    this.$nextTick(() => {
      this.getScroll();
    });
  },
  methods: {
    getScroll() {
      if (this.current2 == 2) {
        document.getElementById("cu2").scrollIntoView({ behavior: "smooth" });
      }
    },
    // 跳转到党建详情页
    gotoDetail(val) {
      this.$router.replace({
        path: "/setPartyDetail",
        query: {
          current: val,
        },
      });
    },
    toteamPage(row) {
      sessionStorage.setItem("conid", row.id);
      this.$router.push({
        path: "teamdetal",
        query: {
          detail: row.id,
        },
      });
    },
    toPagedj(row) {
      sessionStorage.setItem("conid", row.id);
      this.$router.push({
        name: "teamdetal",
        params: {
          detail: row,
        },
      });
    },
    toNews(item) {
      // sessionStorage.setItem("conid", 19);
      // this.toPage("/teamdetal");
      if (item.link) {
        window.open(item.link, "_blank");
      }
    },
    tosomePage(row) {
      this.$router.push({
        path: row.link,
      });
    },
    // 党建特色
    async getSwiperList() {
      let res = await this.$http.post(this.$api.listSlideshow, {
        location: "2",
        status: "1",
      });
      this.swiperList = res.data.data;
      this.$nextTick(() => {
        this.initImgSwiper();
      });
    },
    // 支部动态
    async getSwiperList2() {
      let res = await this.$http.post(this.$api.listSlideshow, {
        location: "3",
        status: "1",
      });
      this.swiperList2 = res.data.data;
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
    initBannerSwiper() {
      // eslint-disable-next-line
      let vueComponent = this; //获取vue组件实例
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#banner", {
        loop: true, // 循环模式选项
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //分页器按钮可点击
        },
        // autoplay: false,
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },
    initSwiper() {
      // eslint-disable-next-line
      let vueComponent = this; //获取vue组件实例
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#zbswiper", {
        loop: true, // 循环模式选项
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //分页器按钮可点击
        },
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },
    initImgSwiper() {
      new Swiper("#imgSwiper", {
        loop: false, // 循环模式选项
        slidesPerView: 4, // 根据卡片的宽度自动调整每屏显示的卡片数量
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //导航器
        autoplay: false,
        // 其他配置项...
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    async getList() {
      let res = await this.$http.post(this.$api.listArticle, {
        current: 1,
        size: 3,
        typeId: `5,6`,
        status: "1",
        top: "1",
      });
      this.list = res.data.data.records;
    },
    //非置顶
    async getList2() {
      this.swiperVerticalData = [];
      let res = await this.$http.post(this.$api.listArticle, {
        current: 1,
        size: 100,
        typeId: `5,6`,
        status: "1",
      });
      // this.swiperVerticalData = res.data.data.records
      if (res.data.data.records && res.data.data.records.length > 0) {
        for (let i in res.data.data.records) {
          // if (res.data.data.records[i].top != 1) {
          //   this.swiperVerticalData.push(res.data.data.records[i]);
          // }
          this.swiperVerticalData.push(res.data.data.records[i]);
        }
      }
      localStorage.removeItem("newsListdd");
      localStorage.setItem(
        "newsListdd",
        JSON.stringify(this.swiperVerticalData)
      );
    },
    toPage(row) {
      // console.log(row);
      // this.$toast('敬请期待');
      // return
      sessionStorage.setItem("conid", row.id);
      this.$router.push({
        name: "recruiting",
        params: {
          id: row.id,
        },
      });
    },
    clickNav(index) {
      this.firstIndex = index;
      var navPage = document.querySelector(".right_box" + index);
      // const wrap=this.sysWrap.wrap;
      // wrap.scrollTop=navPage.offsetTop;
      window.scrollTo({
        top: navPage.offsetTop,
        behavior: "smooth",
      });
    },
    handler({ BMap, map }) {
      var point = new BMap.Point(111, 111);
      map.centerAndZoom(point, 19);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
      var circle = new BMap.Circle(point, 1, {
        strokeColor: "Red",
        strokeWeight: 1,
        strokeOpacity: 1,
        Color: "Red",
        fillColor: "#f03",
      });
      map.addOverlay(circle);
    },
    getClickInfo(e) {
      console.log(e.point.lng);
      console.log(e.point.lat);
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
    },

    readDetail() {
      this.$router.replace({ path: "/help2" });
    },
    goHelpMore(i) {
      console.log(i);
      this.$router.replace({ path: "/help1?index=" + i });
    },
    wallet() {
      var i = 0;
      var a = setInterval(() => {
        if (typeof window.ethereum !== "undefined") {
          connectTron().then(
            (info) => {
              clearInterval(a);
              if (info[0].indexOf("0x") == 0) {
                if (info[0] != this.$store.getters.userWallet) {
                  this.buy = true;
                }
                this.$store.commit("userWallet", info[0]);
              } else {
                this.$toast("Use the ETH address to log in to the game");
              }
            },
            (e) => {
              this.$toast(e.message);
            }
          );
        }
        if (i > 5) {
          clearInterval(a);
        }
        i++;
      }, 1000);
    },

    tobuy() {
      location.href = "https://opensea.io/collection/cupid-love-metaverse";
    },
    up() {
      if (this.sqloading) {
        return;
      }
      this.buynum = Number(this.buynum) + 1;
    },
    dow() {
      if (this.sqloading) {
        return;
      }
      this.buynum = this.buynum <= 1 ? 1 : Number(this.buynum) - 1;
    },
    close() {
      this.shows = false;
    },
    handleFiexdToolItem(e) {
      if (!e) {
        return;
      }
      this.$nextTick(() => {
        document.getElementById(e).scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
$t-mf: "Arial";

.main {
  background: #ffffff;
  min-height: 100vh;
  max-width: 100%;
  min-width: 1200px;
  width: 100%;

  .htop {
    height: 64px;
  }

  .banner {
    width: 100%;
    font-size: 0;

    .simg {
      width: 100%;
      height: 550px;
    }

    :deep(.swiper-pagination-bullets) {
      // left: -35%;
      bottom: 100px;
      display: flex;
      width: 1200px;
      left: 0;
      right: 0;
      margin: auto;
    }

    :deep(.swiper-pagination-bullet) {
      width: 36px;
      height: 3px;
      background: #ffffff;
      opacity: 1;
      display: inline-block;
      border-radius: 0%;
    }

    :deep(.swiper-pagination-bullet-active) {
      width: 36px;
      height: 3px;
      opacity: 1;
      // background: #3A89FE;
      background: #ffffff;
      position: relative;
    }

    :deep(.swiper-pagination-bullet-active)::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      height: 3px;
      background: #3a89fe;
      /* 覆盖层颜色 */
      z-index: 1;
      animation: revealColor 5s forwards;
      /* 动画名称、持续时间和填充模式 */
    }

    @keyframes revealColor {
      from {
        width: 0%;
        /* 动画结束时，覆盖层宽度为0，暴露出下面的颜色 */
      }
    }
  }

  // .banner {
  // 	max-width: 100%;
  // 	min-width: 1200px;
  // 	width: 100%;
  // 	margin-bottom: 40px;
  // 	.banimg {
  // 		max-width: 100%;
  // 		min-width: 1200px;
  // 		width: 100%;
  // 		height: 510px;
  // 		position: relative;
  // 		text-align: left;
  // 		background: url("../assets/ximg/banner3.jpg");
  // 		background-size: cover;
  // 		display: flex;
  // 		flex-direction: column;
  // 		align-items: center;
  // 		justify-content: center;
  // 		.title {
  // 			width: 1200px;
  // 			font-size: 36px;
  // 			font-family: Microsoft YaHei;
  // 			font-weight: 400;
  // 			color: #ffffff;
  // 		}
  // 		.tip {
  // 			width: 1200px;
  // 			font-size: 16px;
  // 			font-family: Microsoft YaHei;
  // 			font-weight: 400;
  // 			color: #ffffff;
  // 			margin-top: 23px;
  // 		}
  // 	}
  // }
  .zbbg {
    height: 1007px;
    box-sizing: border-box;
    background: url(~@/assets/sdImages/party/Group1594.png) no-repeat !important;
    background-size: 100% 100% !important;
    background-position: center center;

    .boxzb {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .boxzb-left {
        width: 793px;
        height: 440px;
        border-radius: 8px 8px 8px 8px;

        .swiper-container > .swiper-wrapper > .swiper-slide {
          height: 440px;
          position: relative;

          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            background-color: #f5f5f5;
          }

          .swiper-pagination-text {
            display: flex;
            position: absolute;
            bottom: 0;
            z-index: 999;
            width: 792px;
            background: rgb(148, 29, 30, 0.9);
            padding: 20px;

            .swiper-pagination-text-left {
              margin-right: 20px;
              margin-left: 33px;

              .num {
                height: 30px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 32px;
                color: #ffffff;
                text-align: center;
                font-style: normal;
                text-transform: none;
                margin-bottom: 10px;
              }

              .time {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                font-style: normal;
                text-transform: none;
              }
            }

            .swiper-pagination-text-right {
              width: 446px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              font-size: 18px;
              color: #fbfbfb;
              line-height: 30px;
              text-align: left;
              font-style: normal;
              text-transform: none;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
        }

        .swiper-pagination {
          bottom: 0;
          // background: #941d1e;
          opacity: 0.9;
          height: 87px;
          display: flex;
          align-items: center;
          justify-content: end;

          :deep(.swiper-pagination-bullet) {
            width: 8px;
            height: 8px;
            background: #ffffff;
            border-radius: 100%;
            transform: translateX(-30px) translateY(-2px);
          }

          :deep(.swiper-pagination-bullet-active) {
            border: 2px solid #fbfbfb;
            background: #941d1e;
          }
        }
      }

      .boxzb-right {
        width: 391px;
        height: 440px;
        background: #ffffff;
        border-bottom: 4px solid #851f2b;
        padding-top: 18px;
        box-sizing: border-box;

        .boxzb-right-list-box {
          height: 380px;
          overflow-y: auto;
        }

        .boxzb-right-list {
          width: 90%;
          height: 46px !important;
          border-bottom: 1px solid #f4f4f4;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .content {
            // font-family: PingFang TC, PingFang TC;
            font-weight: 400;
            font-size: 14px;
            color: #0f0e0f;
            font-style: normal;
            text-transform: none;
          }

          .time {
            // font-family: PingFang TC, PingFang TC;
            font-weight: 400;
            font-size: 14px;
            color: #8d8f94;
            font-style: normal;
            text-transform: none;
          }
        }

        .boxzb-right-btn {
          display: flex;
          justify-content: space-between;

          .up {
            width: 40%;
            text-align: center;
            color: #941d1e;
            font-size: 24px;
            font-weight: 500;
            cursor: pointer;
          }

          .line {
            height: 20px;
            margin-top: 10px;
            border: 1px solid #eeeeee;
          }

          .down {
            width: 40%;
            text-align: center;
            color: #941d1e;
            font-size: 24px;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }

      .boxzb-bottom {
        margin-top: 16px;
        width: 389px;

        .boxzb-bottom-img {
          width: 100%;
          height: 167px;
          overflow: hidden;

          img {
            width: 100%;
            height: 167px;
            transition: 0.35s;
            object-fit: cover;
          }

          img:hover {
            transform: scale(1.1);
          }
        }

        .boxzb-bottom-text {
          width: 100%;
          height: 66px;
          padding: 0 15px 0 15px;
          box-sizing: border-box;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 16px;
          color: #000000;
          line-height: 66px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          background: #ffffff;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }
    }
  }

  .main_box {
    width: 100%;
    background: #ffefdf;
    padding: 100px 0;

    // box-sizing: border-box;
    // padding: 32px 28px;
    // display: flex;
    // flex-direction: column;
    // text-align: left;
    // cursor: default;
    .dj-title {
      text-align: center;
      margin: 0px auto 60px auto;
      font-family: Inter, Inter;
      font-weight: bold;
      font-size: 32px;

      font-style: normal;
      text-transform: none;

      .title-red {
        color: #e50112;
      }

      .title-black {
        color: #2b2c2f;
      }
    }

    .mbg {
      width: 100%;
      height: auto;
    }

    .box1 {
      // margin-top: 45px;
      width: 1200px;
      height: 564px;
      box-sizing: border-box;
      margin: 0 auto;
      position: relative;
      padding: 45px 44px 39px 40px;
      background: linear-gradient(180deg, #fae5d1 0%, #ffd5c5 100%);
      border-radius: 10px 10px 10px 10px;

      .red-text {
        color: #e50112;
      }

      .left_con {
        // margin-right: 37px;
        .title {
          font-size: 28px;
          //   font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin: 45px 0;
        }

        .cont {
          margin-bottom: 23px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #242222;
          line-height: 31px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }

      .out-swiper-container {
        position: relative;
        margin-top: 55px;
      }

      .swiper-container1 {
        position: initial;
      }

      .swiper-container1 > .swiper-wrapper > .swiper-slide {
        height: 200px;

        img {
          width: 266px;
          height: 100%;
          object-fit: cover;
        }
      }

      /* 自定义按钮样式 */
      .swiper-button-next {
        width: 42px;
        height: 42px;
        background: url(~@/assets/sdImages/party/Group12370@2x.png) no-repeat;
        background-size: 100% 100%;
        /* 设置背景颜色 */
        opacity: 1;
        color: #cccccc;
        z-index: 99;
      }

      .swiper-button-prev {
        width: 42px;
        height: 42px;
        background: url(~@/assets/sdImages/party/Group12373@2x.png) no-repeat;
        background-size: 100% 100%;
        /* 设置背景颜色 */
        opacity: 1;
        color: #cccccc;
        z-index: 99;
      }

      .swiper-button-prev.swiper-button-disabled {
        background: url(~@/assets/sdImages/party/Group12371@2x.png) no-repeat;
        background-size: 100% 100%;
      }

      .swiper-button-next.swiper-button-disabled {
        background: url(~@/assets/sdImages/party/Group12372@2x.png) no-repeat;
        background-size: 100% 100%;
      }

      .swiper-button-next::after {
        font-size: 0;
      }

      .swiper-button-prev::after {
        font-size: 0;
      }

      .swiper-button-prev {
        top: 252px;
        left: 43%;
      }

      .swiper-button-next {
        top: 252px;
        right: 43%;
      }

      /* 鼠标悬浮时改变样式 */
      .swiper-button-next:hover,
      .swiper-button-prev:hover {
        opacity: 1;
        /* 更改悬浮时的透明度 */
      }
    }

    .box4 {
      display: flex;
      margin-top: 45px;
      justify-content: center;
      width: 1200px;
      margin: 80px auto;
      align-items: center;
      flex-direction: column;

      // background-color: #f5f6fa;
      .right_title {
        font-size: 28px;
        // font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .right_tip {
        font-size: 14px;
        // font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }

      .box_cont {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 36px;

        .img {
          width: 280px;
          height: 186px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #dcdee0;
          margin: 12px 0;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 250px;
            height: 166px;
          }
        }
      }
    }
  }

  .about_box {
    width: 100%;
    height: 808px;
    background: url(~@/assets/sdImages/party/Group1571.png) no-repeat;
    background-size: cover;
    background-position: 100%;
    // 	background-image: url(~@/assets/sdImages/party/Group1571.png);
    // background-position: 100%;
    // background-size: 100% 100%;
    // background-repeat: no-repeat;

    padding: 100px 0;
    box-sizing: border-box;

    .btop {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0px auto 20px;

      .title {
        // font-size: 32px;
        // font-weight: 500;
        // color: #1D2129;
        // line-height: 45px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 36px;
        color: #ffffff;
        line-height: 30px;
        font-style: normal;
        text-transform: none;
        margin-bottom: 33px;
      }

      .tips {
        // font-size: 16px;
        // font-weight: 400;
        // color: #646566;
        // line-height: 22px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
        line-height: 30px;
        font-style: normal;
        text-transform: none;
      }
    }

    .box2 {
      width: 1200px;
      margin: 0 auto;

      // display: flex;
      // align-items: center;
      // justify-content: space-around;
      .left-flag {
        display: flex;
        justify-content: start;
        // font-family: Plus Jakarta Sans, Plus Jakarta Sans;
        font-weight: 500;
        font-size: 128px;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
        height: 68px;

        img {
          width: 36px;
          height: 32px;
        }
      }

      .center-content {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #ffffff;
        line-height: 39px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      .right-flag {
        display: flex;
        justify-content: end;
        // font-family: Plus Jakarta Sans, Plus Jakarta Sans;
        font-weight: 500;
        font-size: 128px;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
        height: 68px;

        img {
          width: 36px;
          height: 32px;
        }
      }
      .ts-img {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .img {
          width: 286px;
          height: 200px;
          overflow: hidden;
          border-radius: 8px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            transition: 0.35s;
          }
        }

        img:hover {
          transform: scale(1.1);
        }
      }

      .item {
        display: flex;
        align-items: flex-start;

        .img {
          img {
            width: 129px;
            height: 129px;
          }
        }

        .cont {
          margin-left: 54px;

          .title {
            font-size: 24px;
            // font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1d2129;
            line-height: 33px;
            text-align: left;
            margin-bottom: 24px;
          }

          .txt {
            font-size: 16px;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #646566;
            text-align: left;
          }
        }
      }
    }
  }

  .box3 {
    margin-top: 60px;
    text-align: center;

    .right_title {
      font-size: 28px;
      //   font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    .right_tip {
      font-size: 14px;
      //   font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }

    .right_con {
      display: flex;
      margin-top: 35px;
      justify-content: center;

      img {
        width: 1200px;
      }
    }
  }

  .box6 {
    text-align: center;
    background: #f4f6fe;

    //  margin-top:50px;
    .right_title {
      margin-top: 50px;
      font-size: 28px;
      //   font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    .right_tip {
      font-size: 14px;
      //   font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-bottom: 40px;
      // line-height: 60px;
    }

    .box_cont {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 1226px;
      margin: 0 auto 80px;

      .item {
        width: 280px;
        height: 186px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dcdee0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        margin: 13px;

        .title {
          font-size: 18px;
          //   font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 25px;
        }

        .btn {
          min-width: 122px;
          height: 40px;
          background: #ffffff;
          border-radius: 2px;
          font-size: 16px;
          //   font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2b6bff;
          line-height: 40px;
          margin-top: 20px;
          border: 1px solid #2b6bff;
          cursor: pointer;
        }
      }

      .item:hover {
        box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
      }
    }
  }
}

.h5 {
  min-width: 100%;

  .htop {
    height: 125px;
  }

  .banner {
    width: 100%;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .simg {
      width: 1000px;
      height: auto;
    }
  }

  .main_box {
    .box1 {
      width: 100%;
      flex-direction: column;
      align-items: center;
      padding: 32px 0;
      height: auto;

      .img {
        margin-top: 34px;
        position: static;

        img {
          width: 494px;
          height: 367px;
        }
      }

      .left_con {
        margin: 0 37px;
        width: 100%;

        .title {
          font-size: 36px;
          text-align: center;
          font-weight: bold;
        }

        .cont {
          font-size: 26px;
          line-height: 42px;
          margin: 0 auto 40px;
          width: calc(100% - 64px);
        }
      }
    }

    .box4 {
      width: 100%;

      .right_title {
        font-size: 36px;
        font-weight: bold;
      }

      .right_tip {
        font-size: 26px;
        margin: 10px auto;
      }

      .box_cont {
        padding: 32px 28px;
        width: calc(100% - 56px);

        .img {
          width: calc(50% - 30px);
        }
      }
    }
  }

  .box3 {
    .right_title {
      font-size: 36px;
      font-weight: bold;
    }

    .right_tip {
      font-size: 26px;
      margin: 10px auto;
    }

    .right_con {
      padding: 32px 28px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .about_box {
    .box2 {
      width: 100%;
      flex-direction: column;

      .item {
        width: calc(100% - 60px);
        margin: 0 auto;
        align-items: center;

        .cont {
          .title {
            font-size: 32px;
            margin: 10px 0;
          }

          .txt {
            font-size: 26px;
            height: 36px;
          }
        }
      }
    }
  }

  .box6 {
    .box_cont {
      width: calc(100% - 56px);
      margin: 50px auto;

      .item {
        width: calc(50% - 26px);

        .title {
          font-size: 26px;
          line-height: 42px;
        }

        .btn {
          font-size: 24px;
        }
      }
    }
  }
}

@media screen and (min-width: 0px) {
  .Gtitle {
    text-align: center;

    h1 {
      font-size: 24px;
      font-weight: 500;
      color: #000000;
      line-height: 45px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: #969799;
      line-height: 22px;
    }
  }

  .enter_box {
    position: relative;
    padding: 30px 0;

    .fzbg {
      display: none;
      width: 100%;
    }

    ul {
      margin-top: 30px;

      li {
        margin-bottom: 40px;
        padding-left: 60px;
        position: relative;
        z-index: 1;

        &:last-child {
          &::before {
            display: none;
          }
        }

        &::after {
          content: "";
          width: 30px;
          height: 30px;
          border-radius: 50%;
          position: absolute;
          left: 10px;
          top: 0px;
          background-color: #f2f6fe;
          z-index: -1;
        }

        &::before {
          content: "";
          width: 1px;
          height: calc(100% + 20px);
          position: absolute;
          left: 25px;
          top: 0px;
          background-color: #2b6bff;
          z-index: -1;
        }

        h4 {
          font-size: 18px;
          font-weight: 500;
          color: #1d2129;

          &::after {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            left: 20px;
            top: 10px;
            background-color: #2b6bff;
          }
        }

        p {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #646566;
        }
      }
    }
  }
}

@media screen and (min-width: 760px) {
}

@media screen and (min-width: 820px) {
}

@media screen and (min-width: 1100px) {
  .Gtitle {
    h1 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
      margin-top: 14px;
    }
  }

  .enter_box {
    padding: 0;
    margin-top: 80px;

    .Gtitle {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }

    ul {
      display: none;
    }

    .fzbg {
      display: block;
    }
  }
}

@media screen and (min-width: 1300px) {
}
</style>
